import React, { useState, useEffect } from 'react';
import { makeStyles, IconButton, Typography, Grid, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, MenuItem, Button, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ReactComponent as IconFilter } from 'assets/svgs/product_filter.svg';
import { ReactComponent as IconClose } from 'assets/svgs/product_close.svg';
import IconRefresh from '@material-ui/icons/Refresh'
import { mapFiltersFromTableData } from 'utils'
import CustomDatePicker from 'components/CustomDatePicker'
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    container: {

    },
    icon: {
        fill: 'inherit !important'
    },
    filterSection: {
        padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
        backgroundColor: 'white',
        borderTopLeftRadius: theme.spacing(3),
        borderBottomLeftRadius: theme.spacing(3),
        marginTop: theme.spacing(3),
        minHeight: '80%'
    },
    filterTitle: {
        fontSize: 18
    },
    filterDesp: {
        fontSize: 12,
        color: '#4E5864',
        lineHeight: '18px',
        margin: theme.spacing(1, 0)
    },
    filtericon: {
        width: 25,
        height: 25
    },
    table: {
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5)
    },
    formControl: {
        '&:before': {
            borderBottomStyle: 'dashed',
        },
        '&:after': {
            borderBottomStyle: 'dashed',
        }
    },
    filterItem: {
        margin: theme.spacing(1, 0)
    },
    menulist: {
        maxWidth: 350
    },
    menuItem: {
        whiteSpace: 'pre-line'
    },
    btnWrap: {
        textAlign: 'center',
        marginTop: theme.spacing(2)
    }
}));

const Filters = ({ intl: { formatMessage },
    setOpenFilter,
    setOnFilter,
    columns,
    data
}) => {
    const classes = useStyles();
    const [options, setOptions] = useState({})
    const [filters, setFilters] = useState({})
    const [datekey, setDatekey] = useState(new Date());

    useEffect(() => {
        if (data && columns) {
            setOptions(mapFiltersFromTableData(data, columns.filter(item => item.options && item.options.filter).map(item => item.name)))
        }
    }, [columns, data])

    return (
        <Grid item sm={3} style={{ padding: '0px !important' }}>
            <List className={classes.filterSection}>
                <ListItem dense style={{ padding: 0 }}>
                    <ListItemIcon>
                        <IconFilter className={classes.filtericon} />
                    </ListItemIcon>
                    <ListItemText primary={<FormattedMessage id="table.filter.button" />} primaryTypographyProps={{ className: classes.filterTitle }} />
                    <ListItemSecondaryAction>
                        <IconButton size="small" onClick={() => setOpenFilter(false)}><IconClose fill="#030F40" /></IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                {/* <Typography className={classes.filterDesp}><FormattedMessage id="catalogue.searchbar.filters.description" /></Typography> */}
                <br />
                {
                    Object.keys(options).map(key => {
                        const find_col = columns.find(item => item.name == key);
                        return <Grid key={key} container spacing={2} alignItems="center" className={classes.filterItem}>
                            <Grid item xs={12} sm={5}>
                                <Typography variant="h6" style={{ textAlign: 'left', color: "#464F73" }}><strong>{find_col && find_col.label || ''}</strong></Typography>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                {
                                    find_col?.options.filterType == "date" ?
                                        <>
                                            <CustomDatePicker
                                                key={datekey}
                                                autoOk={true}
                                                disableToolbar
                                                format="DD/MM/YYYY"
                                                defaultValue={filters[key] || null}
                                                value={filters[key] || null}
                                                onChange={date => {
                                                    if (date && !moment(date).isValid()) return;
                                                    const newValue =date ? moment.utc(moment(date).format("DD/MM/YYYY"), "DD/MM/YYYY"): date;
                                                    setFilters({
                                                        ...filters,
                                                        [key]: newValue
                                                    })
                                                }}
                                                fullWidth
                                                placeholder="DD/MM/YYYY"
                                                helperText=''
                                                InputLabelProps={{ shrink: true }}
                                                error={false}
                                            />
                                        </> :
                                        <Select
                                            value={filters[key] || ''}
                                            className={classes.formControl}
                                            fullWidth
                                            onChange={e => {
                                                e.persist()
                                                setFilters({
                                                    ...filters,
                                                    [e.target.name]: e.target.value
                                                })
                                            }}
                                            name={key}
                                            MenuProps={{ classes: { paper: classes.menulist } }}
                                        >
                                            <MenuItem value="" className={classes.menuItem}>
                                                <em>{find_col?.options.allText ? find_col?.options.allText : <FormattedMessage id="all" />}</em>
                                            </MenuItem>
                                            {
                                                find_col?.options.customFilterList ?
                                                    find_col.options.customFilterList.sort((a, b) => a.label.localeCompare(b.label)).map(item => (
                                                        <MenuItem key={item.value} value={item.value} className={classes.menuItem}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))
                                                    : options[key].sort((a, b) => a.localeCompare(b)).map(item => (
                                                        <MenuItem key={item} value={item} className={classes.menuItem}>
                                                            {item}
                                                        </MenuItem>
                                                    ))
                                            }
                                        </Select>
                                }
                            </Grid>
                        </Grid>
                    })
                }
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} className={classes.btnWrap}>
                        <Button variant="contained" color="primary" style={{ borderRadius: 4 }}
                            onClick={() => {
                                setOnFilter(filters)
                            }}>Apply</Button>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.btnWrap}>
                        <Button startIcon={<IconRefresh />} onClick={() => {
                            setOnFilter({})
                            setFilters({})
                            setDatekey(new Date())
                        }}>Reset</Button>
                    </Grid>
                </Grid>
            </List>
        </Grid>
    );
}
Filters.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
    })
};
export default injectIntl(Filters);